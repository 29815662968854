import {useQuery} from "react-query";
import axios from "axios";

const list = []

const specFilter = (data)=>{
    const seen = new Map();
    const arr = []
    const duplicates = [];


    data.forEach(i =>{
        const key = `${i['НоменклатураБренд']}-${i['НоменклатураМодель']}`;
        if (i['Номенклатура'].toLowerCase().includes('ботинки') || i['Номенклатура'].toLowerCase().includes('коньки')){
            //const modifiedString = i['Номенклатура'].replace(/размер.{3}/, '').replace(/\b\d+-\d+\b/g, '');
            const modifiedString = i['Номенклатура'] .replace(/размер\s*\d+(-\d+)?/g, '')
            if (modifiedString) {
                const numberMatch = i['Номенклатура'].match(/(\d+)/);
                const number = numberMatch ? numberMatch[0] : '';
                i['Номенклатура'] = modifiedString
                i['Размер2'] = `${number}`
            }
            if (seen.has(key)){
                duplicates.push(i);
                arr.find(item => {
                    if (item['НоменклатураБренд'] === i['НоменклатураБренд'] && item['НоменклатураМодель'] === i['НоменклатураМодель']){
                        item['Размер2'] = `${item['Размер2']},${i['Размер2']}`
                    }
                })
            } else {
                seen.set(key, i)
                arr.push(i)
            }

        } else {
            arr.push(i)
        }
        /*if (i['Номенклатура'].toLowerCase().includes('ботинки')){
            const modifiedString = i['Номенклатура'].replace(/размер.{3}/, '');
            if (modifiedString) {
                const numberMatch = i['Номенклатура'].match(/(\d+)/);
                const number = numberMatch ? numberMatch[0] : '';
                i['Номенклатура'] = modifiedString
                i['Размер'] = `${number}`
            }
            if (seen.has(key)){
                duplicates.push(i);
                arr.find(item => {
                    if (item['НоменклатураБренд'] === i['НоменклатураБренд'] && item['НоменклатураМодель'] === i['НоменклатураМодель']){
                        item['Размер'] = `${item['Размер']},${i['Размер']}`
                    }
                })
            } else {
                seen.set(key, i)
                arr.push(i)
            }

        } else {
            arr.push(i)
        }*/
    })
   /* console.log(duplicates)
    console.log(seen)
    console.log(data)*/
    return arr
}



async function fetchCatalogData(){
    const res = (await axios.get('https://storage.yandexcloud.net/velomarketkoleso/data/data.json')).data
    const x = res.filter(i => i.ВНаличии >= 1)
    //console.log(x)
    const newX = specFilter(x)
    return newX;
}
async function fetchCatalogService(){
    const res = (await axios.get('https://storage.yandexcloud.net/velomarketkoleso/data/data.json')).data
    return res;
}

export const useGetCatalogData = () => {
    return useQuery('catalog', fetchCatalogData,
        {
            keepPreviousData: true,
            refetchOnWindowFocus: true,
        })
}

export const useGetService = () => {
    return useQuery('service', fetchCatalogService,
        {
            keepPreviousData: true,
            refetchOnWindowFocus: true,
        })
}